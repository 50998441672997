* {
  font-family: 'Montserrat Regular';
}
body {
  background-color: transparent;
}
.App {
  text-align: center;
  background: white;
  padding-bottom: 15px;
}

.App-logo {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.App .container {
  max-width: 600px;
  margin: 0 auto;
  transition: 1;
}

.App-header {
  min-height: 20vh;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.alergen {
  font-weight: bold;
  /* color: red; */
  /* color: black */
  border-bottom: 1px solid red;
}

.alergen::after {
  /* content: ' '; */
  /* border: 5px solid blue; */
  /* border-radius: 20px; */
  /* background: red; */
  /* width: 5px; */
  /* height: 10px; */
  content: ' \25CF';
  font-size: small;
  color: red;
}

.App-link {
  color: #61dafb;
}

.footer-logo {
  margin-top: 100px;
  height: 20vmin;
}

.footer-desc {
  color: #757575;
  margin: 10px auto 10px auto;
  font-size: x-large;
}

.footer-sns {
  margin: 20px auto 20px auto;
  display: flex;
}

.footer-sns-body {
  margin: 0 auto;
  display: flex;
}

.footer-url {
  margin: 20px auto 50px auto;
}

.menu-name {
  color: #757575;
  text-align: right;
  font-size: large;
  padding: 50px 20px 10px 0px;
  border-bottom: 1px solid #CACACA;
}

.category-header {
  font-family: 'Montserrat Bold';
  font-size: large;
  color: black;
  text-align: left;
  padding: 20px 20px 20px 20px;
  border-bottom: 1px solid #CACACA;
  cursor: pointer;
}

.category-slot {
  float: right;
  transition: transform 0.1s;
}

.slot-extended {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: transform 0.1s;
}

.category-itemlist {
  padding: 0px 20px 0px 20px;
  transition: all 0.1s ease;
}

.visible {
  /* max-height: 10000px; */
  visibility: visible;
}

.invisible {
  max-height: 0;
  visibility: hidden;
}

.item-body {
  margin: 20px 10px 20px 10px;
}

.item-edit {
  text-align: left;
  display: flex;
  width: 100%;
  margin-right: 2em;
  font-family: 'Montserrat Bold';
  position: relative;
}

.item-name {
  text-align: left;
  display: flex;
  font-family: 'Montserrat Bold';
}

.item-price {
  float: right;
  white-space: nowrap;
  font-family: 'Montserrat Bold';
}

.item-desc {
  text-align: left;
  margin: 15px 0px 15px 0px;
  font-size: medium;
}

.item-image-flex {
  display: flex;
}

.item-image {
  width: 100%;
}

.image-holder {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 4px 4px 4px 4px;
  border-radius: 20px;
}

.slick-prev:before, .slick-next:before {
  color: black;
}

.sns-wrap {
  margin: 0px 10px 0px 10px;
}

.sns-link {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.login-title {
  color: #6F88D8;
  font-family: 'Montserrat Bold';
  font-size: xx-large;
  margin: 100px auto 100px auto;
}

.login-input {
  width: 15em;
  height: 2.5em;
  margin: 10px auto 10px auto;
  padding-left: 0.5em;
  padding-right: 0.5em;
  outline: 0;
  border-width: 0 0 2px;
  border-color: #757575;
}

.login-input:focus {
  border-color: #C5C5C5;
}

.login-button {
  color: #fcfcff;
  background-color: #6F88D8;
  font-family: 'Montserrat Bold';
  font-size: large;
  width: 12em;
  height: 2.5em;
  margin: 20px auto 40px auto;
  border-radius: 1.5em;
  border: none;
  outline: none;
}

.login-forgot-desc {
  color: #757575;
  font-family: 'Montserrat Bold';
  margin: 0px auto 20px auto;
}

.login-forgot-desc a {
  color: #757575;
}

.login-signup-desc {
  color: #757575;
  margin-right: 10px;
}

.login-signup-link {
  color: #757575;
}

.login-status {
  color: red;
  margin: 10px 20px 0px 20px;
  font-size: small;
}

.btn-add-category {
  color: #fcfcff;
  background-color: #5F77FF;
  font-family: 'Montserrat Bold';
  font-size: large;
  width: 90%;
  height: 2.3em;
  margin: 20px auto 40px auto;
  border-radius: 0.3em;
  border: none;
  outline: none;
}

.disabled {
  background-color: #9E9E9E;
}

.verify-warning {
  color: #886509;
  background-color: #FFF3CD;
  padding: 0.5em 0 0.5em 2em;
  margin: 5px;
  border-radius: 5px;
  text-align: left;
}

.warning-paragraph {
  margin: 0.5em 0 0.5em 0;
}

.edit-item-top {
  display: flex;
  width: 100%;
  margin: 20px auto 10px auto;
}

.edit-item-name {
  color: black;
  background-color: #EBEBEB;
  width: 100%;
  height: 2.5em;
  margin: 0 1em 0 1em;
  padding: 0 0.5em 0 0.5em;
  outline: none;
  border: none;
  border-radius: 0.5em;
  text-align: left;
  font-family: 'Montserrat Bold';
}

.edit-item-price {
  color: black;
  background-color: #EBEBEB;
  width: 5em;
  height: 2.5em;
  margin-right: 1em;
  padding: 0 0.5em 0 0.5em;
  outline: none;
  border: none;
  border-radius: 0.5em;
  font-family: 'Montserrat Bold';
}

.edit-item-bottom {
  display: flex;
  width: 100%;
  margin: 0px auto 10px auto;
}

.edit-item-desc {
  color: black;
  background-color: #EBEBEB;
  width: 100%;
  height: 5em;
  margin: 0em 1em 0em 1em;
  padding: 0.5em;
  outline: none;
  border: none;
  border-radius: 0.5em;
  font-family: 'Montserrat Regular';
  resize: none;
}

.edit-item-button {
  display: flex;
  width: 100%;
  margin: 0px auto 20px auto;
}

.edit-item-button-left {
  width: 35%;
  margin-left: 1em;
}

.edit-item-button-right {
  width: 65%;
  margin-left: 0.5em;
  margin-right: 1em;
}

.btn-save-product {
  color: #fcfcff;
  background-color: #00a651;
  font-family: 'Montserrat Bold';
  font-size: large;
  width: 100%;
  height: 2.3em;
  margin: auto;
  border-radius: 0.3em;
  border: none;
  outline: none;
}

.btn-cancel-product {
  color: #fcfcff;
  background-color: #898989;
  font-family: 'Montserrat Bold';
  font-size: large;
  width: 100%;
  height: 2.3em;
  margin: auto;
  border-radius: 0.3em;
  border: none;
  outline: none;
}

.rpt-container {
  left: 0!important;
}

.tooltip-black {
  color: black;
}

.tooltip-red {
  color: red;
}

.item-operation {
  position: relative;
  color: #B1B1B1;
  margin-right: 0.5em;
  display: inline-block;
  float: left;
}

.item-operation > svg {
  pointer-events: none;
} 

.image-hollow-holder {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #EBEBEB;
  margin: 4px 4px 4px 4px;
  border-radius: 10px; 
  display: flex;
  justify-content: center;
  position: relative;
}

.item-image-holder-flex {
  display: flex;
  margin: 0 1em 1em 1em;
  justify-content: space-between;
}

.item-image-holder-desc {
  color: #c1c1c1;
  font-size: small;
}

.delete-icon-container {
    background: rgba(255, 255, 255, 0.618);
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
}

.popup-edit-container {
  margin-left: 22px;
  position: relative;
  margin-top: -8px;
}

.search {
  padding: 14px 20px 14px 20px;
  border-radius: 15px;
  border: 1px solid #ccc;
  width: 80%;
  font-family: 'Montserrat Regular';
  transition: 0.3s;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: calc(100% - 52px);
  left: 26px;
  /* padding: 10px; */
  transition: 0.3s;
  font-size: 16px;
  z-index: 9;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
/* padding-top: 120px; */
}

.editable-input {
  background-color: #dadada;
  margin: 5px 15px;
  border-radius: 10px;
  padding: 10px;
}

.input-footer-text {
  font-size: 14px;
}

.text-logo {
  font-size: 50px;
  margin-bottom: 20px;
  color: #000
}

.quick-button {
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px 40px;
  border-radius: 10px !important;
  font-size: 20px;
  border: 1px solid #ccc;
  align-self: center;
}
.quick-button-small {
  font-size: 12px;
  padding: 5px 15px;
}
footer .text-logo {
  margin-top: 100px
}
.btn-facebook {
  color: #fff !important;
  background-color: #4267b2 !important;
  border-color: #4267b2 !important;
  border-radius: 10px !important; 
}
.btn-messenger {
  color: #fff !important;
  background-color: #0083ff !important;
  border-color: #0083ff !important;
  border-radius: 10px !important;
}
.btn-call {
  color: #fff !important;
  background-color: #00b066 !important;
  border-color: #00b066 !important;
  border-radius: 10px !important;
}
.btn-email {
  color: #fff !important;
  background-color: #702eb1 !important;
  border-color: #702eb1 !important;
  border-radius: 10px !important;
}
.info-small-buttons-container {
  margin-left: 5px;
  margin-top: 20px;
}
.info-small-buttons-container a[role=button]{
  width: 48%;
  margin-left: 1%;
  margin-right: 1%;
  padding: 10px 20px;
}
.deactivated {
  font-size: x-small !important;
}